<template>
  <div>
    <div class="main-container">

      <div class="main-title">테마 상품 신청 내역</div>

      <div class="menu-title columns is-multiline">
        <div class="column is-3 lp-menu">테마 이름</div>
        <div class="column is-6 lp-menu">신청 정보</div>
        <div class="column is-3 lp-menu">상태</div>
      </div>

      <div class="space-top"></div>

      <div class="col-wrap">
        <div class="columns is-multiline lp-row-border-bottom"
             v-for="(menu, idx) in menus"
             :key="'menu-'+idx">
          <!--서비스명-->
          <div class="column is-3">
            <div class="service-name" v-if="menu.service_name">{{menu.service_name}}</div>
            <div class="service-name" v-else>-</div>
          </div>

          <!--테마/요금-->
          <div class="column is-6 flex">
            <div>
              <div class="card-img" :style="imgField(menu.imgs[0])"></div>
            </div>
            <div class="info-price">{{menu.product_name}} / {{menu.price|currency}} / {{ menu.created_time }}</div>
          </div>
          <!--상태-->
          <div class="column is-3">
            <div class="service-name" style="width:unset">
              {{contentStatus(menu.status, menu.service_status)}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "ThemeRequestList",
    data() {
      return {
        menus: [],
        isActive:true,
        hasError:false

      }
    },
    created() {
      this.getMenus();
    },

    methods:{
      getMenus(){
        this.$axios.get(`user/${this.$store.getters.user.user_id}/launchpack/theme/request`)
          .then(res => {
            this.menus = res.data.data;
          });

      },
      contentImg(img){
        return {
          backgroundImage: `url(${img})`,
          width:'57px',
          height:'57px'
        }
      },
      contentStatus(status, service_status) {
        let value = '';
        if (status===0) value = '신청완료';
        else if(status===2 && service_status===1) value = '상담 후 솔루션 이용 결정'
        else if(status===2 && service_status===2) value = '상담 후 솔루션 이용 포기'
        else if (status===1) value = '상담 대기중';
        else if (status===2) value = '상담 완료';
        return value;
      }
    }
  }
</script>

<style scoped>

  .card-img{
    position: relative;
    width: 54px;
    height:54px;
    border-radius: 3px 3px 0 0;
    background-color: white;
    margin:20px 0 20px 0;
  }

  .main-container{
    /*border:1px solid black;*/
    width: 100%;
    height:100%;
    /*display: flex;*/
  }


  .main-title{
    height: 27px;
    font-size: 18px;
    text-align: center;
    line-height: 27px;
    color:#393e46;
    margin:175px 0 40px 0;
    font-weight: 900;
  }
  .col-wrap{
    margin: auto;
    height:100%;
    margin-bottom:80px;
  }

  .columns {
    width: 1080px;
    border-spacing: 0;
    margin: 0 auto;
  }

  .service-name{
    height: 27px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    text-align: left;
    color: #393e46;
    margin:27px 0 27px 0;

  }
  .e-mail{
    width: 190px;
    height: 19px;
    font-size: 13px;
    font-weight: 300;
    line-height: 1.46;
    text-align: left;
    color: #45b7ff;
  }
  .e-mail2{
    color:#989898;
  }
  .theme-img{
    width: 54px;
    height:54px;
    background-color:#fae0d4;
    /*transform: translateX(512px);*/    /*position: relative;*/
  }
  .column {
    height:100px;
    background-color: #ffffff;
  }
  .info-price{
    font-size: 13px;
    text-align: left;
    line-height: 19px;
    /*width: 234px;*/
    width: 100%;
    margin-top: 25px;
    margin-left: 12px;
    /*padding-top:27px;*/
  }
  .menuStatus{
    font-size: 13px;
    /*padding-top:13px;*/
    /*font-weight: bold;*/
  }

  .theme-tool{
    display: flex;
    margin:27px auto;
  }


  .btn1, .btn2, .btn3{
    display: flex;
    align-items: center;
    width:130px;
    height: 40px;
    border-radius: 3px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    font-size: 13px;
    margin-left: 10px;
  }
  .btn2{
    background-color:#f5f5f5;
  }

  .btn1:hover, .btn2:hover, .btn3:hover{
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
  .btn3{
    margin-top:27px;
  }
  .design-txt, .admin-txt{
    width: 80px;
    height: 19px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #393e46;
    display: inline-block;

  }

  .lp-row-border-bottom{
    border-bottom: 1px solid #e6e6e6;
  }

  .menu-title{
    width: 1080px;
    height: 56px;
    background-color: #f5f5f5;
    margin: 0 auto;
    display: flex;
  }

  .lp-menu{
    background-color: #f5f5f5;
    height: 100%;
    line-height: 33px;
    letter-spacing: normal;
    text-align: left;
    font-size: 13px;
    color: #989898;
  }

  .first-menu{
    flex: 1;
    margin: 19px 0 18px 20px;
    width: 60px;
    height: 19px;
    font-size: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #989898;
  }
  .second-menu{
    flex: 2;
    margin: 19px 0 18px 220px;
    width: 60px;
    height: 19px;
    font-size: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #989898;
  }
  .third-menu{
    flex: 3;
    margin: 19px 0 18px 140px;
    width: 60px;
    height: 19px;
    font-size: 13px;
    letter-spacing: normal;
    text-align: left;
    color: #989898;
  }

  .fourth-menu{

  }

  .space-top{
    width: 1080px;
    height: 20px;
    margin: 0 auto;
    /*border:1px solid grey;*/
  }

  .design_fix, .admin_page{
    width: 16px;
    height: 16px;
    margin-left:7px;
  }
  .awaken_img{
    width: 16px;
    height: 16px;
    margin-left:7px;
  }

  .menu-time{
    font-size:13px;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    height: 95px;
  }
  .menu-text{
    color:red;
  }
  .time-txt{
    width: 254px;
    height: 45px;
    margin-left: 15px;
    text-align: left;
  }

  .awaken-txt{
    width: 80px;
    height: 19px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: center;
    color: #393e46;
    display: inline-block;

  }

  .status1 {
    font-weight: bold;
    color: black;
    margin-left: 65px;
    width: 100px;
  }

  .status2 {
    font-weight: bold;
    color: #ff5722;
    margin-left: 65px;
    width: 100px;
  }

  .status3 {
    font-weight: bold;
    color: grey;
    margin-left: 65px;
    width: 100px;
  }

  /*.is-desktop{*/
  /*width:100%;*/
  /*!*border:1px solid black;*!*/
  /*border-spacing: 0;*/
  /*!*border-collapse: collapse;*!*/
  /*margin: 0 auto;*/
  /*!*table-layout: fixed;*!*/
  /*word-break: break-all;*/
  /*border: none;*/
  /*}*/

  .status{
    width: 100px;
  }
  @media(max-width:768px) {
    .main-container{
      height: auto;
    }
    .col-wrap{
      width: 74%;
    }

    .service-name{
      text-align: center;
    }

    .menu-title {
      content:"min-width:768px";
      display:none;

    }

    .columns{
      width:48%
    }

    .menu-time{
      height:0;
    }

    .time-txt{
      text-align: center;
      margin-left:10px;
    }

    .btn1{
      margin-left: 0;
    }
    .btn3{
      margin-left: 27%;
    }
    .menuStatus{
      padding-top:16px;
    }
  }

  /*@media only screen and (max-device-width: 575px){*/
  /*.sub-title-container:nth-child(3){*/
  /*box-sizing: border-box;*/
  /*width: 280px;*/
  /*!*margin-left: 6.8%;*!*/
  /*}*/
  /*}*/

  @media(max-width:575px){
    .columns{
      width:100%
    }

    .time-txt{
      text-align: center;
      margin-left:5%;
    }
    .btn1{
      margin-left:1px;
    }
    .btn3{
      margin-left:27%;
    }
  }

  @media(min-width:1024px){
    .col-wrap {
      width: 1080px;
    }

  }





  /*가로모드 모바일 디바이스 (가로 해상도가 768px 보다 작은 화면에 적용)*/
  @media (max-width: 767px) {

  }

  /*태블릿 디바이스 (가로 해상도가 992px 보다 작은 화면에 적용)*/
  /*@media (max-width: 991px) {*/
  /*.col-wrap{*/
  /*width:100%;*/
  /*}*/
  /*}*/

  /*테스크탑 (가로 해상도가 1200px 보다 작은 화면에 적용)*/
  @media (max-width: 1199px) {  }

  /*desktop,laptop*/

  @media only screen
  and (min-width : 1025px) {

  }




</style>
